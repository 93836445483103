// 保存
export const saveLocalInfo = function (name, data) {
    localStorage.setItem(name, JSON.stringify(data));
  };
  
  // 获取
  
  export const getLocalInfo = function (name) {
    const data = localStorage.getItem(name);
    return data ? JSON.parse(data) : data;
  };
  
  // 清除
  export const clearLocalInfo = function (name) {
    localStorage.removeItem(name);
  };
  
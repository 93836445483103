import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import {saveLocalInfo,getLocalInfo,clearLocalInfo} from "./utils/storage";
import axios from "axios";
import '@/assets/icon/iconfont.css'
import iview from 'iview';
import 'iview/dist/styles/iview.css';
import VueScrollTo from 'vue-scrollto'
import VueRouter from 'vue-router'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(iview);
Vue.use(VueScrollTo)
Vue.use(VueRouter)
Vue.use(Vant);
Vue.prototype.setStore = saveLocalInfo;
Vue.prototype.getStore = getLocalInfo;
Vue.prototype.removeStore = clearLocalInfo;
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import Vue from "vue";
import VueRouter from "vue-router";
const index = () => import("../views/index");
const about = () => import("../views/about");
const products = () => import("../views/products");
const productsDetail = () => import("../views/products/detail");
const information = () => import("../views/information");
const informationDeatil = () => import("../views/information/detail");
const contact = () => import("../views/contact");
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes: [
    {name: 'index',path: '/',component: index,meta: { title: "/index" }},
    {name: 'about',path: '/about',component: about,meta: { title: "/about" }},
    {name: 'products',path: '/products',component: products,meta: { title: "/products" }},
    {name: 'productsDetail',path: '/productsDetail',component: productsDetail,meta: { title: "/products" }},
    {name: 'information',path: '/information',component: information,meta: { title: "/information" }},
    {name: 'informationDeatil',path: '/informationDeatil',component: informationDeatil,meta: { title: "/information" }},
    {name: 'contact',path: '/contact',component: contact,meta: { title: "/contact" }},
  ]
})
router.beforeEach((to, from, next) => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  next();
});

router.afterEach((to, from, next) => {
  document.title = "海南虎尔美网络科技有限公司";
  var newDiv = document.createElement("script");
  newDiv.src = 'https://api.tianditu.gov.cn/api?v=4.0&tk=0b6cb198f61c4622d631413918769625'
  newDiv.type = 'text/javascript'
  document.head.appendChild(newDiv)
});
export default router; 
 
<template>
  <div id="app">
    <keep-alive>
       <router-view :key="$route.fullPath" :isPc="isPc"/>
    </keep-alive>
  </div>
</template>
  
<script>
export default {
  name: 'app',
  data(){
    return{
      mennu: false,
      isPc: true,
      isRouterAlive: true,
      historyData: [], // 历史路由数据
      nowTime: 0, // 当前所在历史的时间戳
      timer: false,
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.historyRoute(val);
      },
      immediate: true
    },
  },
  created(){
    this.$nextTick(() => {
        this.isPc = window.innerWidth >= 1200;
        window.addEventListener("load", this.loadHandle);
           //禁用右键菜单
        document.oncontextmenu = new Function("event.returnValue=false");
        //禁用鼠标选中
        document.onselectstart = new Function("event.returnValue=false");
        //禁止键盘F12键
        document.addEventListener("keydown", function (e) {
            if (e.key == "F12") {
              e.preventDefault(); // 如果按下键F12,阻止事件
            }
        });
    })
  },
  mounted(){
      window.onload = function() {
      document.addEventListener('keydown', function (event) {
        if ((event.ctrlKey === true || event.metaKey === true)
            && (event.which === 61 || event.which === 107
            || event.which === 173 || event.which === 109
            || event.which === 187  || event.which === 189))
        {
            event.preventDefault();
        }
    }, false);
  }
},
  methods:{
    loadHandle() {
      window.addEventListener("resize", this.resizeHandle);
    },
    resizeHandle() {
      this.isPc = window.innerWidth >= 1200;
    },
    historyRoute(val) {
    let param = {
      url: val.path,
      time: new Date().getTime()
    };
    window.history.replaceState(param, " ", location.origin);
    this.historyData.push(param);
  },
  trigger(e) {
    let that = this;
    let time = e.state.time;
    if (that.nowTime === 0) {
      // 第一次一定是后退按钮
    } else {
      if (time >= that.nowTime) {
        // 前进
      } else {
        // 后退
      }
    }
    that.nowTime = time;
    that.toHistory(that.nowTime);
  },
  toHistory(time) {
    let data = this.historyData.find(e => e.time === time);
    this.$router.replace(data.url);
    window.history.replaceState({}, " ", location.origin);
  },
  },
  beforeDestroy() {
    window.removeEventListener("load", this.loadHandle);
    window.removeEventListener("resize", this.resizeHandle);
    window.removeEventListener("popstate", this.trigger, false);
  }
}
</script>
<style>
::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
</style>